import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import styles from '../styles/LikeExercise.module.css';

const LikeExercise = ({ exerciseId, reset }) => {
  const { t } = useTranslation();
  const [liked, setLiked] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [hoveredThumb, setHoveredThumb] = useState(null);
  const [showReason, setShowReason] = useState(false);
  const [reason, setReason] = useState('');

  useEffect(() => {
    setLiked(null);
    setSubmitted(false);
    setHoveredThumb(null);
    setShowReason(false);
    setReason('');
  }, [exerciseId, reset]);

  const handleLike = async (isLiked) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/exercises/like', {
        exerciseId,
        liked: isLiked
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      setLiked(isLiked);
      setShowReason(true);
    } catch (error) {
      console.error('Error submitting exercise like:', error);
    }
  };

  const handleSubmitComment = async () => {
    if (submitted || !reason.trim()) return;
    
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/exercises/comment', {
        exerciseId,
        comment: reason.trim()
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      setSubmitted(true);
      setShowReason(false);
    } catch (error) {
      console.error('Error submitting exercise comment:', error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmitComment();
    }
  };

  if (submitted) {
    return (
      <div className="text-center mt-3">
        <p className="text-secondary small">{t('like_thanks')}</p>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center mt-3 gap-2">
      <div className="d-flex align-items-center gap-3">
        <p className="text-secondary small mb-0">{t('like_question')}</p>
        <i 
          className={`bi ${liked === true || hoveredThumb === 'up' ? 'bi-hand-thumbs-up-fill ' + styles.filled : 'bi-hand-thumbs-up'} fs-5 ${liked === true ? 'text-success ' + styles.success : 'text-secondary'} ${styles.thumbIcon}`}
          onClick={() => handleLike(true)}
          onMouseEnter={() => setHoveredThumb('up')}
          onMouseLeave={() => setHoveredThumb(null)}
          style={{ cursor: submitted ? 'default' : 'pointer' }}
        />
        <i 
          className={`bi ${liked === false || hoveredThumb === 'down' ? 'bi-hand-thumbs-down-fill ' + styles.filled : 'bi-hand-thumbs-down'} fs-5 ${liked === false ? 'text-danger ' + styles.danger : 'text-secondary'} ${styles.thumbIcon}`}
          onClick={() => handleLike(false)}
          onMouseEnter={() => setHoveredThumb('down')}
          onMouseLeave={() => setHoveredThumb(null)}
          style={{ cursor: submitted ? 'default' : 'pointer' }}
        />
      </div>
      {showReason && (
        <div className={styles.reasonContainer}>
          <input
            type="text"
            className={`form-control form-control-sm ${styles.reasonInput}`}
            placeholder={liked ? t('like_like_prompt') : t('like_improve_prompt')}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button 
            className={`btn btn-sm btn-primary ${styles.submitButton}`}
            onClick={handleSubmitComment}
          >
            {t('submit')}
          </button>
        </div>
      )}
    </div>
  );
};

export default LikeExercise;