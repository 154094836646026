import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/HomePage.module.css';
import ScorePlot from '../components/ScorePlot';
import { useTranslation } from 'react-i18next';
import ExerciseUsage from '../components/ExerciseUsage';
import TrialUsage from '../components/TrialUsage';
import constants from '../config/constants';

const readingExercises = [
  { name: 'multiple_choice', exercise: 'multiple_choice', icon: 'bi-list-check' },
  { name: 'true_false_not_given', exercise: 'true_false_not_given', icon: 'bi-check-circle' },
  { name: 'gap_filling', exercise: 'gap_filling', icon: 'bi-distribute-vertical' },
  { name: 'matching_headings', exercise: 'matching_headings', icon: 'bi-text-paragraph' },
  { name: 'sentence_completion', exercise: 'sentence_completion', icon: 'bi-pencil-square' },
  { name: 'table_completion', exercise: 'table_completion', icon: 'bi-table' },
  { name: 'note_completion', exercise: 'note_completion', icon: 'bi-journal-text' },
  { name: 'summary_completion', exercise: 'summary_completion', icon: 'bi-funnel' },
  { name: 'short_answer_questions', exercise: 'short_answer_questions', icon: 'bi-question-circle' },
];

const listeningExercises = [
  { name: 'multiple_choice', exercise: 'multiple_choice', icon: 'bi-list-check' },
  { name: 'true_false_not_given', exercise: 'true_false_not_given', icon: 'bi-check-circle' },
  { name: 'matching_speakers', exercise: 'matching_speakers', icon: 'bi-people' },
  { name: 'sentence_completion', exercise: 'sentence_completion', icon: 'bi-pencil-square' },
  { name: 'table_completion', exercise: 'table_completion', icon: 'bi-table' },
  { name: 'note_completion', exercise: 'note_completion', icon: 'bi-journal-text' },
  { name: 'summary_completion', exercise: 'summary_completion', icon: 'bi-funnel' },
  { name: 'short_answer_questions', exercise: 'short_answer_questions', icon: 'bi-question-circle' },
];

const writingExercises = [
  { name: 'essay', exercise: 'essay', icon: 'bi-pencil-square' },
  { name: 'letter', exercise: 'letter', icon: 'bi-envelope-paper' },
  { name: 'table_description', exercise: 'table_description', icon: 'bi-table' },
  { name: 'text_summarisation', exercise: 'text_summarisation', icon: 'bi-funnel' },
  { name: 'graph_description', exercise: 'graph_description', icon: 'bi-graph-up' },
  { name: 'chart_description', exercise: 'chart_description', icon: 'bi-bar-chart' },
];

const speakingExercises = [
  { name: 'monologue', exercise: 'monologue', icon: 'bi-chat-dots' },
  // { name: 'picture_description', exercise: 'picture_description', icon: 'bi-image' },
  { name: 'argumentation', exercise: 'argumentation', icon: 'bi-chat-right-quote' },
  { name: 'compare_contrast', exercise: 'compare_contrast', icon: 'bi-arrows-expand-vertical' },
  // { name: 'interview', exercise: 'interview', icon: 'bi-people' },
  // { name: 'discussion', exercise: 'discussion', icon: 'bi-people' },
];

const HomePage = () => {
  const { user, loading, refreshUser } = useContext(AuthContext);
  const location = useLocation();
  const { t } = useTranslation(); // Initialize translation hook
  const [showThankYou, setShowThankYou] = useState(false);
  const [customerEmail, setCustomerEmail] = useState('');
  const [redirectToCheckout, setRedirectToCheckout] = useState(false);
  const [usageLimits, setUsageLimits] = useState({});

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const upgradedSessionId = queryParams.get('upgraded_session_id');
    if (upgradedSessionId) {
      axios.get(`/api/payments/session-status?session_id=${upgradedSessionId}`)
        .then((response) => {
          if (response.data.status === 'complete') {
            setShowThankYou(true);
            setCustomerEmail(response.data.customer_email);
            refreshUser();
          } else if (response.data.status === 'open') {
            setRedirectToCheckout(true);
          }
        });
    }
  }, [location.search, refreshUser]);

  useEffect(() => {
    if (!user) return;

    // Convert usage data to limits object
    const limits = {
      reading: (user.exerciseUsage?.reading || 0) >= constants.exerciseLimits[user.role].reading,
      listening: (user.exerciseUsage?.listening || 0) >= constants.exerciseLimits[user.role].listening,
      writing: (user.exerciseUsage?.writing || 0) >= constants.exerciseLimits[user.role].writing,
      speaking: (user.exerciseUsage?.speaking || 0) >= constants.exerciseLimits[user.role].speaking
    };

    // Check if trial period has ended for basic accounts
    if (user.role === 'basic') {
      const trialEndDate = new Date(user.createdAt);
      trialEndDate.setDate(trialEndDate.getDate() + constants.trialDays);
      const trialEnded = new Date() > trialEndDate;
      if (trialEnded) {
        limits.reading = true;
        limits.listening = true;
        limits.writing = true;
        limits.speaking = true;
      }
    }

    setUsageLimits(limits);
  }, [user]);

  // const renderExercises = (exercises, section) => (
  //   <ul className="list-group mt-3">
  //     {exercises.map((exercise, index) => (
  //       <li key={index} className="list-group-item fs-5 p-0" style={{ backgroundColor: '#fcfcfc' }}>
  //         <Link
  //           to={`/${section}/${exercise.exercise}`}
  //           className={`${styles.listItemLink} d-flex align-items-center w-100 h-100 py-2 px-3`}
  //         >
  //           <i className={`bi ${exercise.icon} me-2`}></i>
  //           {t(`${exercise.name}`)} {/* e.g. 'multiple_choice' */}
  //         </Link>
  //       </li>
  //     ))}
  //   </ul>
  // );

  const renderExercises = (exercises, section) => (
    <ul className="list-group mt-3">
      {exercises.map((exercise, index) => (
        <li key={index} className="list-group-item fs-5 p-0" style={{ backgroundColor: '#fcfcfc' }}>
          {usageLimits[section] ? (
            // If limit is reached, disable link and redirect to /premium with an upgrade icon
            <Link
              to="/premium"
              className={`${styles.listItemLink} d-flex align-items-center w-100 h-100 py-2 px-3`}
              style={{ color: '#a0a0a0' }}
            >
              <i className={`bi ${exercise.icon} me-3`}></i>
              <div className="fs-6">{t(`${exercise.name}`)}</div>
              {/* Upgrade icon at the end */}
              <i className="bi bi-lock ms-1" title="Upgrade for access"></i>
            </Link>
          ) : (
            // Regular link to exercise if limit is not reached
            <Link
              to={`/${section}/${exercise.exercise}`}
              className={`${styles.listItemLink} d-flex align-items-center w-100 h-100 py-2 px-3`}
            >
              <i className={`bi ${exercise.icon} me-3`}></i>
              <div className="fs-6">{t(`${exercise.name}`)}</div>
            </Link>
          )}
        </li>
      ))}
    </ul>
  );  

  if (loading) {
    return (
      <div className="text-center mt-5">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">{t('home_loading')}</span>
        </div>
      </div>
    );
  }

  if (redirectToCheckout) {
    return <Navigate to="/upgrade" />;
  }

  return <>
    <div className="container mt-4 col-xxl-10">
      {showThankYou && (
        <div className="alert alert-success text-center" role="alert">
          {t('home_thank_you_message', { customerEmail })}
        </div>
      )}

      <h1 className="text-center mb-4">{t('home_page_title')}</h1>

      {user.role === 'basic' && <TrialUsage style={{ marginBottom: '10px' }} />}
      <div className="row mt-4">
        <div className="col-lg-3 col-md-6 mb-4 mt-2">
          <h2 className="text-center mb-3 fs-4">
            <i className="bi bi-book me-2"></i> {t('reading')}
          </h2>
          <ScorePlot 
            section="reading" 
            aspectratio="3"
            targetLanguage={user.targetLanguage}
          />
          <ExerciseUsage category="reading" />
          {renderExercises(readingExercises, 'reading')}
        </div>
        <div className="col-lg-3 col-md-6 mb-4 mt-2">
          <h2 className="text-center mb-3 fs-4">
            <i className="bi bi-headphones me-2"></i> {t('listening')}
          </h2>
          <ScorePlot 
            section="listening" 
            aspectratio="3"
            targetLanguage={user.targetLanguage}
          />
          <ExerciseUsage category="listening" />
          {renderExercises(listeningExercises, 'listening')}
        </div>
        <div className="col-lg-3 col-md-6 mb-4 mt-2">
          <h2 className="text-center mb-3 fs-4">
            <i className="bi bi-pencil me-2"></i> {t('writing')}
          </h2>
          <ScorePlot 
            section="writing" 
            aspectratio="3"
            targetLanguage={user.targetLanguage}
          />
          <ExerciseUsage category="writing" />
          {renderExercises(writingExercises, 'writing')}
        </div>
        <div className="col-lg-3 col-md-6 mb-4 mt-2">
          <h2 className="text-center mb-3 fs-4">
            <i className="bi bi-mic me-2"></i> {t('speaking')}
          </h2>
          <ScorePlot 
            section="speaking" 
            aspectratio="3"
            targetLanguage={user.targetLanguage}
          />
          <ExerciseUsage category="speaking" />
          {renderExercises(speakingExercises, 'speaking')}
        </div>
      </div>
    </div>
  </>;
};

export default HomePage;
