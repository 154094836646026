import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useTranslation } from 'react-i18next'; 
import constants from '../config/constants';

const PremiumPage = () => {
  const { t } = useTranslation();
  
  // Calculate total exercises
  const totalExercises = 
    constants.exerciseLimits.premium.reading + 
    constants.exerciseLimits.premium.listening + 
    constants.exerciseLimits.premium.writing + 
    constants.exerciseLimits.premium.speaking;

  return (
    <div className="container col-xxl-8 mt-5">
      <h1 className="text-center mb-3">{t('upgrade_to_premium')}</h1>
      <p className="text-center mb-4 fs-5">{t('premium_description', { count: totalExercises })}</p>
      {/* Premium Plan */}
      <div className="col d-flex mb-3" style={{ maxWidth: '500px', margin: '0 auto', marginTop: '40px' }}>
          <div className="card w-100 rounded-3 shadow-sm border-primary h-100 d-flex flex-column">
            <div className="card-header py-3 text-bg-primary border-primary position-relative">
              <h4 className="my-0 fw-normal text-center">{t('pricing_premium_plan')}</h4>
              <span className="position-absolute badge rounded-pill bg-danger" 
                    style={{ 
                      fontSize: '1.2rem',
                      fontWeight: '700',
                      padding: '0.9rem 1.3rem',
                      top: '-15px',
                      right: '-20px',
                      transform: 'rotate(12deg)',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.25)'
                    }}>
                {t('pricing_premium_discount_percentage', { percentage: constants.prices.discount })}
              </span>
            </div>
            <div className="card-body d-flex flex-column">
              {/* Updated Price Section */}
              <div className="mb-4 mt-4 d-flex flex-column align-items-center justify-content-center">
                <h1 className="card-title pricing-card-title fw-normal mb-1" style={{ fontSize: '2.5rem' }}>
                  <span className="text-decoration-line-through text-muted me-2">{constants.prices.standard}</span>
                  {constants.prices.discounted}
                  <small className="text-body-secondary fw-light">{t('pricing_premium_duration')}</small>
                </h1>
                <p className="text-danger mb-0 fs-5 text-center">{t('pricing_premium_launch_offer')}</p>
                <p className="text-primary text-center mt-2 fs-5">
                  <i className="bi bi-tag-fill me-2"></i>
                  {t('use_code')} <span className="badge bg-primary px-2">{constants.prices.launchOfferCode}</span> {t('at_checkout')}
                </p>
              </div>
              {/* Features Section for Premium */}
              <ul className="text-center list-unstyled mb-4 flex-grow-1 d-flex flex-column justify-content-center" style={{ fontSize: '1.1rem' }}>
                <li className="mb-1">
                  <i className="bi bi-book me-2"></i>
                  {t('pricing_reading', { count: constants.exerciseLimits.premium.reading })} {t('pricing_premium_duration')}
                </li>
                <li className="mb-1">
                  <i className="bi bi-headphones me-2"></i>
                  {t('pricing_listening', { count: constants.exerciseLimits.premium.listening })} {t('pricing_premium_duration')}
                </li>
                <li className="mb-1">
                  <i className="bi bi-pencil me-2"></i>
                  {t('pricing_writing', { count: constants.exerciseLimits.premium.writing })} {t('pricing_premium_duration')}
                </li>
                <li className="mb-1">
                  <i className="bi bi-mic me-2"></i>
                  {t('pricing_speaking', { count: constants.exerciseLimits.premium.speaking })} {t('pricing_premium_duration')}
                </li>
                <li><i className="bi bi-envelope me-2"></i>{t('pricing_premium_email_support')}</li>
              </ul>
              {/* CTA Section */}
              <div className="mt-auto d-flex align-items-center justify-content-center">
                <Link to="/upgrade" className="w-100 btn btn-lg btn-primary">{t('upgrade_now')}</Link>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default PremiumPage;
