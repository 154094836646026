import React, { useState, useEffect } from "react";
import GapItem from "./GapItem";
import Explanations from "./Explanations";

const GapFilling = ({ passageTitle, passageSegments, onComplete }) => {
  const [userAnswers, setUserAnswers] = useState({});
  const [explanations, setExplanations] = useState([]);
  const [shuffledOptions, setShuffledOptions] = useState({});

  const shuffleArray = (array) => {
    if (!Array.isArray(array)) return [];
    
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  useEffect(() => {
    let gapCounter = 0;
    passageSegments.forEach((segment) => {
      if (segment.gap && segment.gap.answer && !shuffledOptions[gapCounter]) {
        setShuffledOptions(prev => ({
          ...prev,
          [gapCounter]: shuffleArray(segment.gap.options)
        }));
      }
      if (segment.gap) gapCounter++;
    });
  }, [passageSegments]);

  const handleOptionChange = (gapIndex, selectedOption) => {
    let gapCounter = 0;

    const gapSegment = passageSegments.find((segment, idx) => {
      if (segment.gap) {
        if (gapCounter === gapIndex) return true;
        gapCounter++;
      }
      return false;
    });
    
    if (!gapSegment?.gap) return;

    const isCorrect = selectedOption === gapSegment.gap.answer;

    setUserAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers, [gapIndex]: { userAnswer: selectedOption, isCorrect } };

      const newExplanation = {
        number: gapIndex + 1,
        text: gapSegment.gap.explanation
      };
      setExplanations((prevExplanations) => [...prevExplanations, newExplanation]);

      const totalGaps = passageSegments.filter(segment => segment.gap).length;
      if (Object.keys(newAnswers).length === totalGaps) {
        const score = Object.values(newAnswers).filter(answer => answer.isCorrect).length / totalGaps;
        onComplete(score);
      }

      return newAnswers;
    });
  };

  const renderFilledPassage = () => {
    if (!passageSegments?.length) return null;
    
    let gapCounter = 0;

    return (
      <div className="alert alert-light" style={{ textAlign: "justify" }}>
        {passageTitle && (
          <div className="fs-4 mb-3 text-center">{passageTitle}</div>
        )}
        {passageSegments.map((segment, index) => (
          <React.Fragment key={index}>
            {segment.text && <span>{segment.text}</span>}
            {segment.gap && segment.gap.options && (
              <GapItem
                gapData={{
                  ...segment.gap,
                  options: shuffledOptions[gapCounter] || segment.gap.options
                }}
                gapIndex={gapCounter++}
                selectedOption={userAnswers[gapCounter - 1]?.userAnswer}
                isCorrect={userAnswers[gapCounter - 1]?.isCorrect}
                onOptionChange={handleOptionChange}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div>
      {renderFilledPassage()}
      {explanations.length > 0 && <Explanations explanations={explanations} />}
    </div>
  );
};

export default GapFilling;
