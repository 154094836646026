import React, { useState, useRef, useEffect, useContext } from "react";
import { AuthContext } from '../context/AuthContext';
import ErrorMessage from "./ErrorMessage";
import FeedbackSpeaking from "./FeedbackSpeaking";
import AudioRecorder from "./AudioRecorder";
import Instructions from "./Instructions";
import Prompt from "./Prompt";
import Image from "./Image";
import ExerciseTitle from "./ExerciseTitle";
import useExerciseTitleFromApi from "../hooks/useExerciseTitleFromApi";
import ExerciseUsage from "./ExerciseUsage";
import useStreamExercise from "../hooks/useStreamExercise";
import useStreamFeedback from "../hooks/useStreamFeedback";
import ExerciseNavigation from "./ExerciseNavigation";
import { useNavigate } from 'react-router-dom';
import CorrectedResponse from "./CorrectedResponse";
import constants from '../config/constants';
import LikeExercise from './LikeExercise';

const SpeakingExercise = ({ api }) => {
  const { user, refreshUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { exercise, exerciseId, error: exerciseError, refetchExercise } = useStreamExercise(api);
  const { feedback, setFeedback, error: feedbackError, submitExercise } = useStreamFeedback(api);
  const feedbackWasNull = useRef(true);
  const [likeReset, setLikeReset] = useState(0);

  useEffect(() => {
    if (feedback !== null && feedbackWasNull.current) refreshUser();
    feedbackWasNull.current = feedback === null;
  }, [feedback, refreshUser]);

  const handleStopRecording = (blob) => {
    const formData = new FormData();
    formData.append("exerciseId", exerciseId);
    formData.append("audio", blob, `audio.${blob.type.split("/")[1]}`);
    submitExercise(formData);
  };

  const handleRefresh = () => {
    setFeedback(null);
    refetchExercise();
    setLikeReset(prev => prev + 1);
  };

  return (
    <div className="container mt-4 col-xxl-10" style={{ maxWidth: '900px' }}>
      <ExerciseTitle
        title={useExerciseTitleFromApi(api)}
        onRefresh={handleRefresh}
        disableRefresh={user.exerciseUsage.speaking >= constants.exerciseLimits[user.role].speaking} />
      <ExerciseUsage category="speaking" style={{ marginBottom: '6px' }} />
      {(exerciseError || feedbackError) && <ErrorMessage error={exerciseError || feedbackError} />}
      {!exerciseError && !feedbackError && <>
        <Instructions instructions={exercise?.instructions} />
        {exercise?.prompt && <Prompt prompt={exercise.prompt} />}
        {exercise?.imageUrl && <Image url={exercise.imageUrl} />}
        {user.exerciseUsage.speaking < constants.exerciseLimits[user.role].speaking &&
          <AudioRecorder onStopRecording={handleStopRecording} isSubmitting={false} />}
        {feedback && <>
          <CorrectedResponse text={feedback.correction} />
          <FeedbackSpeaking feedback={feedback} />
        </>}
        <LikeExercise exerciseId={exerciseId} reset={likeReset} />
      </>}
      <ExerciseNavigation
        onBack={() => navigate('/home')}
        onNext={handleRefresh}
        disableNext={user.exerciseUsage.speaking >= constants.exerciseLimits[user.role].speaking} />
    </div>
  );
};

export default SpeakingExercise;
