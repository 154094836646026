import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../context/AuthContext';
import logo from '../assets/logo_with_ostrich.png';
import styles from '../styles/Header.module.css';
import LanguageSelect from './LanguageSelect';

const Header = () => {
  const { t } = useTranslation();
  const { user, logout } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const handleLogout = () => {
    logout(() => (window.location.href = '/'));
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    closeMenu(); // Close menu on route change
  }, [location]);

  // Menu items for non-logged-in users
  const nonAuthMenuItems = [
    { to: '/#features', label: t('features'), icon: 'bi-grid' },
    { to: '/#about-us', label: t('about_us'), icon: 'bi-info-circle' },
    { to: '/#pricing', label: t('pricing'), icon: 'bi-tag' },
    { to: '/#faq', label: t('faq'), icon: 'bi-question-circle' },
  ];

  // Menu items for logged-in users
  const authMenuItems = [
    { to: '/', label: t('home'), icon: 'bi-house' },
    { to: '/feedback', label: t('feedback'), icon: 'bi-chat-text' },
    ...(user?.role !== 'premium' ? [{ to: '/premium', label: t('upgrade'), icon: 'bi-arrow-up-circle' }] : []),
    ...(user?.role === 'admin' ? [{ to: '/admin', label: t('admin'), icon: 'bi-tools' }] : []),
  ];

  return (
    <nav className="navbar navbar-expand-lg p-3" style={{ borderBottom: '1px solid rgb(222, 226, 230)', backgroundColor: '#fcfcfc' }}>
      <div className="container-fluid px-3">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Logo" width="110" className="d-inline-block align-baseline mx-1" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-controls="navbarSupportedContent"
          aria-expanded={isMenuOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {(user ? authMenuItems : nonAuthMenuItems).map(({ to, label, icon }, index) => (
              <li className="nav-item" key={index}>
                <Link className={`nav-link ${styles.navLink}`} to={to} onClick={closeMenu}>
                  <i className={`bi ${icon} me-2`}></i> {label}
                </Link>
              </li>
            ))}
          </ul>
          <LanguageSelect />
          <div className="d-flex mt-3 mt-lg-0 ms-lg-2">
            {user ? (
              <>
                {/* Settings Button */}
                <Link to="/settings" className={`btn btn-outline-primary me-2 ${styles.btnMobile}`} onClick={closeMenu}>
                  <i className="bi bi-gear me-1"></i> {t('settings')}
                </Link>
                {/* Sign Out Button */}
                <button onClick={() => { handleLogout(); closeMenu(); }} className={`btn btn-primary ${styles.btnMobile}`}>
                  <i className="bi bi-box-arrow-right me-1"></i> {t('sign_out')}
                </button>
              </>
            ) : (
              <>
                {/* Login and Sign Up for Non-logged-in Users */}
                <Link to="/login" className={`btn btn-outline-primary me-2 ${styles.btnMobile}`} onClick={closeMenu}>
                  <i className="bi bi-box-arrow-in-right me-1"></i> {t('login')}
                </Link>
                <Link to="/signup" className={`btn btn-primary ${styles.btnMobile}`} onClick={closeMenu}>
                  <i className="bi bi-person-plus me-1"></i> {t('sign_up')}
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
