import React from 'react';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import constants from '../config/constants';

const FAQ = () => {
  const { t } = useTranslation(); // Initialize translation

  const faqs = [
    {
      question: t('faq_question_1'),
      answer: t('faq_answer_1'),
    },
    {
      question: t('faq_question_2'),
      answer: t('faq_answer_2'),
    },
    {
      question: t('faq_question_3'),
      answer: t('faq_answer_3'),
    },
    {
      question: t('faq_question_4'),
      answer: t('faq_answer_4'),
    },
    {
      question: t('faq_question_5'),
      answer: t('faq_answer_5', {
        readingCount: constants.exerciseLimits.basic.reading,
        listeningCount: constants.exerciseLimits.basic.listening,
        writingCount: constants.exerciseLimits.basic.writing,
        speakingCount: constants.exerciseLimits.basic.speaking
      }),
    },
    {
      question: t('faq_question_6'),
      answer: t('faq_answer_6', {
        readingCount: constants.exerciseLimits.premium.reading,
        listeningCount: constants.exerciseLimits.premium.listening,
        writingCount: constants.exerciseLimits.premium.writing,
        speakingCount: constants.exerciseLimits.premium.speaking
      }),
    },
    {
      question: t('faq_question_7'),
      answer: t('faq_answer_7'),
    },
    {
      question: t('faq_question_8'),
      answer: t('faq_answer_8'),
    },
    {
      question: t('faq_question_9'),
      answer: t('faq_answer_9'),
    },
  ];

  return (
    <div className="container col-xxl-8 px-4 pt-5">
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
        <h2 className="display-4 fw-normal text-body-emphasis">{t('faq_title')}</h2>
        <p className="fs-5 text-body-secondary">
          {t('faq_subtitle')}
        </p>
      </div>
      <div className="accordion" id="faqAccordion">
        {faqs.map((faq, index) => (
          <div className="accordion-item" key={index}>
            <h3 className="accordion-header" id={`heading${index}`}>
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${index}`}
                aria-expanded="false"
                aria-controls={`collapse${index}`}
                style={{ backgroundColor: '#fcfcfc' }}
              >
                {faq.question}
              </button>
            </h3>
            <div
              id={`collapse${index}`}
              className="accordion-collapse collapse"
              aria-labelledby={`heading${index}`}
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">{faq.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
