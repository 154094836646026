import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import SettingsPage from './pages/SettingsPage';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider, AuthContext } from './context/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import VerifyEmailPage from './pages/VerifyEmailPage';
import UpgradePage from './pages/UpgradePage';
import PremiumRoute from './components/PremiumRoute';
import PremiumPage from './pages/PremiumPage';
import SupportButton from './components/SupportButton';
import './styles/App.css';
// import Exam from './components/Exam';
// import ExamsPage from './pages/ExamsPage';
// import IeltsExam from './components/IeltsExam';
// import ExercisesPage from './pages/ExercisesPage';
import Exercise from './components/Exercise';
import HomePage from './pages/HomePage';
import AdminPage from './pages/AdminPage';
import Setup from './components/Setup';
import FeedbackPage from './pages/FeedbackPage';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<><LoginPage /><SupportButton /></>} />
          <Route path="/signup" element={<><SignupPage /><SupportButton /></>} />
          <Route path="/verify-email" element={<><VerifyEmailPage /><SupportButton /></>} />
          <Route path="/upgrade" element={<><UpgradePage /><SupportButton /></>} />
          <Route path="/*" element={<MainLayout />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

const MainLayout = () => {
  const { user } = useContext(AuthContext);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="flex-grow-1">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<PrivateRoute><HomePage /></PrivateRoute>} />
          <Route path="/settings" element={<PrivateRoute><SettingsPage /></PrivateRoute>} />
          <Route path="/premium" element={<PrivateRoute><PremiumPage /></PrivateRoute>} />
          <Route path="/admin" element={<PrivateRoute><AdminPage /></PrivateRoute>} />
          <Route path="/feedback" element={<PrivateRoute><FeedbackPage /></PrivateRoute>} />
          <Route path="/:section/:exercise" element={<PrivateRoute><Exercise /></PrivateRoute>} />
        </Routes>
      </div>
      <Footer />
      <SupportButton />
      {user && !user.hasCompletedSetup && <Setup />}
    </div>
  );
};

export default App;
