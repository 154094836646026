import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import i18n from 'i18next';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUserLoggedIn = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get('/api/user/profile', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUser(response.data);
        } catch (error) {
          console.error('Error checking logged in user:', error);
          localStorage.removeItem('token');
        }
      }
      setLoading(false);
    };

    checkUserLoggedIn();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post('/api/auth/login', { email, password });
      const { token, ...userData } = response.data;
  
      // Store the token in localStorage
      localStorage.setItem('token', token);
  
      // Check if there's a language in localStorage (set by a guest)
      const localStorageLanguage = localStorage.getItem('language') || 'EN'; // Default to English if none
  
      // Compare localStorage language with userData.knownLanguage
      if (localStorageLanguage !== userData.knownLanguage) {
        // Prioritize localStorage language and sync with backend if they differ
        userData.knownLanguage = localStorageLanguage;
  
        // Update the backend with the guest's chosen language using the PUT /profile route
        await axios.put(
          '/api/user/profile',
          { knownLanguage: localStorageLanguage }, // Send the updated known language
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }
  
      // Set the language in i18n for immediate translation
      i18n.changeLanguage(userData.knownLanguage.toLowerCase());
  
      // Ensure localStorage is updated to reflect the current language
      localStorage.setItem('language', userData.knownLanguage);
  
      // Set user data in context (including the updated knownLanguage)
      setUser(userData);
    } catch (error) {
      console.error('Login failed:', error.response?.data || error.message);
      throw error;
    }
  };  

  const register = async (name, email, password) => {
    try {
      const response = await axios.post('/api/auth/register', { name, email, password });
      return response.data;
    } catch (error) {
      console.error('Registration failed:', error.response?.data || error.message);
      throw error;
    }
  };

  const logout = async (callback) => {
    try {
      await axios.post('/api/auth/logout');
      localStorage.removeItem('token');
      setUser(null);
      if (callback) callback();
    } catch (error) {
      console.error('Error logging out:', error.response?.data || error.message);
      throw error;
    }
  };

  const refreshUser = async () => {
    const token = localStorage.getItem('token');
    if (!token || !user) return;

    try {
      const response = await axios.get('/api/user/profile', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(response.data);
      
      // Update language if it changed
      if (response.data.knownLanguage) {
        i18n.changeLanguage(response.data.knownLanguage.toLowerCase());
        localStorage.setItem('language', response.data.knownLanguage);
      }
    } catch (error) {
      console.error('Error refreshing user data:', error);
      // Handle token expiration
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
        setUser(null);
      }
    }
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      setUser, 
      loading, 
      login, 
      register, 
      logout,
      refreshUser
    }}>
      {children}
    </AuthContext.Provider>
  );
};
