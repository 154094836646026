import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const FeedbackPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('');
    setError('');

    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/feedback/post-feedback', {
        message
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setStatus(t('feedback_submitted'));
      setMessage('');
      setIsSubmitted(true);
    } catch (err) {
      setError(err.response?.data?.message || t('feedback_error'));
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h1 className="text-center mb-4">{t('feedback_title')}</h1>
          
          {isSubmitted ? (
            <div className="text-center">
              <div className="alert alert-success mb-4" role="alert">
                {status}
              </div>
              <button 
                onClick={() => navigate('/')} 
                className="btn btn-primary px-5"
              >
                <i className="bi bi-reply me-2"></i> {t('return_home')}
              </button>
            </div>
          ) : (
            <>
              <p className="text-center mb-4 text-muted">{t('feedback_description')}</p>
              
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}

              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows="8"
                    placeholder={t('feedback_placeholder')}
                    required
                  ></textarea>
                </div>
                <div className="text-center">
                  <button 
                    type="submit" 
                    className="btn btn-primary px-5"
                  >
                    {t('submit_feedback')}
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedbackPage;