import React, { useState, useEffect, useRef, useContext } from "react";
import { AuthContext } from '../context/AuthContext';
import useStreamExercise from "../hooks/useStreamExercise";
import useStreamFeedback from "../hooks/useStreamFeedback";
import WritingArea from "./WritingArea";
import ErrorMessage from "./ErrorMessage";
import FeedbackWriting from "./FeedbackWriting";
import Instructions from "./Instructions";
import Prompt from "./Prompt";
import Table from "./Table";
import SubmitButton from "./SubmitButton";
import Graph from "./Graph";
import Chart from "./Chart";
import ExerciseTitle from "./ExerciseTitle";
import useExerciseTitleFromApi from "../hooks/useExerciseTitleFromApi";
import Passage from "./Passage";
import ExerciseUsage from "./ExerciseUsage";
import ExerciseNavigation from "./ExerciseNavigation";
import { useNavigate } from 'react-router-dom';
import constants from '../config/constants';
import LikeExercise from './LikeExercise';

const WritingExercise = ({ api }) => {
  const { user, refreshUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { exercise, exerciseId, error: exerciseError, refetchExercise } = useStreamExercise(api);
  const { feedback, setFeedback, error: feedbackError, submitExercise } = useStreamFeedback(api);
  const [essayText, setEssayText] = useState('');
  const feedbackWasNull = useRef(true);
  const [likeReset, setLikeReset] = useState(0);

  useEffect(() => {
    if (feedback !== null && feedbackWasNull.current) refreshUser();
    feedbackWasNull.current = feedback === null;
  }, [feedback, refreshUser]);

  const handleEssayChange = (text) => { setEssayText(text); };

  useEffect(() => {
    if (feedback?.correction) {
      setEssayText(feedback.correction);
    }
  }, [feedback?.correction]);

  const cleanSubmission = (text) => {
    return text
      .replace(/<del>[\s\S]*?<\/del>/g, '')
      .replace(/<ins>([\s\S]*?)<\/ins>/g, '$1')
      .replace(/<div>/g, '\n')
      .replace(/<\/div>/g, '');
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("exerciseId", exerciseId);
    formData.append("response", cleanSubmission(essayText));
    submitExercise(formData);
  };

  const handleRefresh = () => { 
    setEssayText(''); 
    setFeedback(null); 
    refetchExercise();
    setLikeReset(prev => prev + 1);
  };

  return (
    <div className="container mt-4 col-xxl-10" style={{ maxWidth: '900px' }}>
      <ExerciseTitle
        title={useExerciseTitleFromApi(api)}
        onRefresh={handleRefresh}
        disableRefresh={user.exerciseUsage.writing >= constants.exerciseLimits[user.role].writing} />
      <ExerciseUsage category="writing" style={{ marginBottom: '6px' }} />
      {(exerciseError || feedbackError) && <ErrorMessage error={exerciseError || feedbackError} />}
      {!(exerciseError || feedbackError) && <>
        <Instructions instructions={exercise?.instructions} />
        {exercise?.prompt && <Prompt prompt={exercise.prompt} />}
        {exercise?.passage && <Passage
          title={exercise.passage.title} 
          passage={exercise.passage.content} />}
        {exercise?.table && <Table table={exercise.table} />}
        {exercise?.graph && <Graph graph={exercise.graph} />}
        {exercise?.chart && <Chart chart={exercise.chart} />}
        <WritingArea value={essayText} onChange={handleEssayChange} />
        {user.exerciseUsage.writing < constants.exerciseLimits[user.role].writing &&
          <SubmitButton onClick={handleSubmit} isSubmitting={false} />}
        {feedback && <FeedbackWriting feedback={feedback} />}
        <LikeExercise exerciseId={exerciseId} reset={likeReset} />
      </>}
      <ExerciseNavigation
        onBack={() => navigate('/home')}
        onNext={handleRefresh}
        disableNext={user.exerciseUsage.writing >= constants.exerciseLimits[user.role].writing} />
    </div>
  );
};

export default WritingExercise;
