import { useContext } from 'react';
import { useParams } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import ReadingExercise from "./ReadingExercise";
import WritingExercise from "./WritingExercise";
import ListeningExercise from "./ListeningExercise";
import SpeakingExercise from "./SpeakingExercise";
import RealtimeSpeakingExercise from './RealtimeSpeakingExercise';

const Exercise = () => {
  const { user } = useContext(AuthContext);
  const { section, exercise } = useParams();
  const level = user.proficiencyLevel;
  const targetLanguage = user.targetLanguage;
  const topic = user.topic;

  const api = `/api/exercises/general/${targetLanguage}/${level}/${section}/${exercise}/${topic}`;

  // Render the exercise component based on the section
  switch (section) {
    case "reading":
      return <ReadingExercise api={api} />;
    case "writing":
      return <WritingExercise api={api} />;
    case "listening":
      return <ListeningExercise api={api} />;
    case "speaking":
      if (exercise === 'interview' || exercise === 'discussion') return <RealtimeSpeakingExercise api={api} />;
      else return <SpeakingExercise api={api} />;
    default:
      return null;
  }
};

export default Exercise;
