import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import constants from '../config/constants';
import { AuthContext } from '../context/AuthContext';

const ExerciseUsage = ({ category, style, trigger }) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  if (!user) return null;

  const used = user.exerciseUsage?.[category] || 0;
  const limit = constants.exerciseLimits[user.role][category];
  const remaining = limit - used;
  
  // Determine which message to show
  let message;
  if (remaining === 0) {
    message = t('exercises_depleted');
  } else if (remaining === 1) {
    message = t('one_exercise_remaining');
  } else {
    message = t('exercises_remaining', { count: remaining });
  }

  // Progress represents remaining amount, but shows 100% when depleted
  const progress = remaining === 0 ? 100 : (remaining / limit) * 100;

  // Determine color class based on remaining exercises
  let colorClass;
  if (remaining === 0) {
    colorClass = 'bg-danger-subtle';
  } else if (remaining <= 3) {
    colorClass = 'bg-danger-subtle';
  } else if (remaining <= 10) {
    colorClass = 'bg-warning-subtle';
  } else {
    colorClass = 'bg-primary-subtle';
  }

  return (
    <div className="mt-2" style={style}>
      <div className="progress" style={{ 
        height: '20px', 
        position: 'relative'
      }}>
        <div
          className={`progress-bar ${colorClass} fs-6`}
          role="progressbar"
          style={{ 
            width: `${progress}%`, 
            color: '#333'
          }}
          aria-valuenow={remaining}
          aria-valuemin="0"
          aria-valuemax={limit}
        >
        </div>
        
        <span 
          style={{
            position: 'absolute',
            top: '0',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            textAlign: 'center',
            color: '#333',
            fontSize: '0.8rem'
          }}
        >
          {message}
        </span>
      </div>
    </div>
  );
};

export default ExerciseUsage;
